import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Main } from '@layout'
import { Box, Grid, Container, Card } from 'theme-ui'
import Section from '@components/Section'
import { graphql } from 'gatsby'
import { SidebarMenu } from '../components/SidebarMenu/SidebarMenu'
import { SidebarLeft } from '../layout/SidebarLeft'
import Product from '../components/ProductCard/ProductCard'
import Breadcrumbs from '../components/Breadcrumbs/AllProductCrumbs'

const AllProducts = ({ data }) => {
  const parents = data.allWcProductsCategories.nodes
  var counter
  const styles = {
    flex: {
      display: 'flex'
    },
    sidebar: {
      display: ['none', 'none', 'none', 'block'],
      minWidth: `auto`
    },
    sidecard: {
      marginTop: -25
    },
    center: {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    }
  }

  return (
    <>
      <Seo
        title='Private Label Product Categories'
        description="View all of Pacific Beauty's private label skincare products organized into categories."
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs />
      </Container>
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <SidebarLeft sx={styles.sidebar}>
          <Section title='Product Menu'>
            <Card variant='paper' sx={styles.sidecard}>
              <SidebarMenu />
            </Card>
          </Section>
        </SidebarLeft>
        <Main>
          {parents.map(parent => (
            <React.Fragment key={parent.id + parent.name + 'fragment'}>
              <Section
                title={parent.name}
                titleLink={'/private-label/' + parent.slug + '/'}
                totalCount={parent.count}
              >
                <Grid gap={3} columns={[1, 1, 3, 3]} sx={styles.center}>
                  {
                    ((counter = 1),
                    parent.products.map(product =>
                      product.status === 'publish' && counter < 4
                        ? counter++ && (
                            <Box key={parent.id + product.id + product.name}>
                              <Product data={product} />
                            </Box>
                          )
                        : null
                    ))
                  }
                </Grid>
              </Section>
              <Divider />
            </React.Fragment>
          ))}
        </Main>
      </Container>
      <Divider />
    </>
  )
}

export const query = graphql`
  {
    allWcProductsCategories(
      filter: {
        wordpress_id: { nin: [96, 97, 98, 99] }
        count: { gt: 0 }
        name: {
          nin: [
            "Skin Essentials"
            "Treatments"
            "Specialty Products"
            "Professional Use"
          ]
        }
      }
    ) {
      nodes {
        id
        name
        slug
        count
        products {
          id
          name
          slug
          images {
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          status
        }
      }
    }
  }
`

export default AllProducts
