import React from 'react'
import { Text } from 'theme-ui'
import { Link } from 'gatsby'
import colors from '../../@gatsby-theme-flexiblog/theme-ui/theme/color-preset'

const styles = {
  breadcrumbs: {
    textTransform: 'uppercase',
    fontSize: '1.25em',
    color: colors.gray[700],
    display: 'block'
  }
}

export default function BreadCrumbs() {
  return (
    <Text sx={styles.breadcrumbs}>
      <Link style={{ color: colors.gray[700] }} to='/'>
        Home
      </Link>
      {' >> '}
      All Products
    </Text>
  )
}
